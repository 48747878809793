<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'

const props = withDefaults(defineProps<{
    // Selector for the container element
    containerSelector?: string,
    threshold?: number
}>(), {
    containerSelector: 'body', // default to body if no selector provided
    threshold: 300
})

const isVisible = ref(false)
const scrollContainer = ref<HTMLElement | null>(null)

const handleScroll = () => {
    if (scrollContainer.value) {
        isVisible.value = scrollContainer.value.scrollTop > props.threshold
    } else {
        // Fallback to window scroll
        isVisible.value = window.scrollY > props.threshold
    }
}

const scrollToTop = () => {
    if (scrollContainer.value) {
        // Scroll within the specific container
        scrollContainer.value.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    } else {
        // Scroll window to top
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }
}

onMounted(() => {
    // Try to find the container element
    scrollContainer.value = document.querySelector(props.containerSelector)

    // Add scroll listener if container exists
    if (scrollContainer.value) {
        scrollContainer.value.addEventListener('scroll', handleScroll)
    } else {
        // Fallback to window scroll listener
        window.addEventListener('scroll', handleScroll)
    }
})

onUnmounted(() => {
    // Remove appropriate scroll listener
    if (scrollContainer.value) {
        scrollContainer.value.removeEventListener('scroll', handleScroll)
    } else {
        window.removeEventListener('scroll', handleScroll)
    }
})
</script>

<template>
    <transition
        enter-active-class="transition ease-out duration-300"
        enter-from-class="opacity-0 scale-90"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="transition ease-in duration-300"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-90"
    >
        <button
            v-if="isVisible"
            @click="scrollToTop"
            class="fixed bottom-6 right-6 bg-indigo-500 text-white p-3 rounded-full shadow-lg hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-all duration-300 ease-in-out z-50"
            aria-label="Scroll to top"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 10l7-7m0 0l7 7m-7-7v18"
                />
            </svg>
        </button>
    </transition>
</template>