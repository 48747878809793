import {defineStore} from "pinia";
import Product from "../../types/Product";
import ProductApiClient from "../../api/ProductApiClient";
import ProductPriceUtils from "./utils/ProductPriceUtils";
import PaginatedResponse from "../../types/PaginatedResponse";
import {ProductListOptions} from "../../types/ProductListOptions";
import ProductPrice from "../../types/ProductPrice";
import Site from "../../types/Site";
import ProductBackInStock from "../../types/ProductBackInStock";
import ProductAvgPrices from "../../types/ProductAvgPrices";
import ProductDeal from "../../types/ProductDeal";

const useProductStore = defineStore({
    id: "products",
    state: () => ({
        products: undefined as PaginatedResponse<Product[]> | undefined,
        searchResults: undefined as Product[] | undefined,
        activeProduct: undefined as Product|undefined,
        activeProductPrices: undefined as ProductPrice[] | undefined,
        activeProductPriceHistory: undefined as ProductPrice[] | undefined,
        activeProductPriceStats: undefined as ProductAvgPrices | undefined,
        sites: undefined as Site[] | undefined,
        latest: undefined as Product[] | undefined,
        backInStock: undefined as ProductBackInStock[] | undefined,
        deals: undefined as ProductDeal[] | undefined,
        ebayPrices: undefined as ProductPrice[] | undefined,
        cardmarketPrices: undefined as ProductPrice[] | undefined
    }),
    actions: {
        async getProduct(gameSlug: string, categorySlug: string, productSlug: string) {
            const response = await ProductApiClient.getProductByCategoryAndProductSlug(gameSlug, categorySlug, productSlug);
            if(response.success){
                this.activeProduct = response.data!;
            }
        },
        async listProductsInCategory(gameSlug: string, categorySlug: string, options?: ProductListOptions) {
            const response = await ProductApiClient.listProductsInCategory(gameSlug, categorySlug, options);
            if(response.success){
                this.products = response.data!;
            }
        },
        async listProductsInSubCategory(gameSlug: string, subCategorySlug: string, options?: ProductListOptions) {
            const response = await ProductApiClient.listProductsInSubCategory(gameSlug, subCategorySlug, options);
            if(response.success){
                this.products = response.data!;
            }
        },
        async getLatestProducts(options?: ProductListOptions) {
            const response = await ProductApiClient.getLatest(options);
            if(response.success){
                this.latest = response.data!;
            }
        },
        async getBackInStockProducts(options?: ProductListOptions) {
            const response = await ProductApiClient.getBackInStock(options);
            if(response.success){
                this.backInStock = response.data!;
            }
        },
        async getProductDeals(options?: ProductListOptions) {
            const response = await ProductApiClient.getDeals(options);
            if(response.success){
                this.deals = response.data!;
            }
        },
        async searchProducts(searchTerm: string, countryCode: string) {
            const response = await ProductApiClient.searchProducts(searchTerm, countryCode);
            if(response.success){
                this.searchResults = response.data!;
            }

        },
        getActiveProductBreadcrumbs(){
            if(this.activeProduct){
                return [
                    {
                        label: this.activeProduct.category.name,
                        path: this.activeProduct.category.slug
                    },
                    {
                        label: this.activeProduct.name,
                        path: `${this.activeProduct.category.slug}/${this.activeProduct.slug}`
                    }
                ];
            }
            return [];
        },
        async getPricingChartData(countryCode: string){
            if(!this.activeProduct){
                return [];
            }
            await this.getProductPriceHistory(this.activeProduct.category.game.slug, this.activeProduct.category.slug, this.activeProduct.slug, countryCode);
            let dates = [] as any[];
            this.activeProductPriceHistory.forEach(p => {
                if(dates.findIndex(y => p.created_at.toISODate() === y.Date) < 0){
                    dates.push({
                        Date: p.created_at.toISODate()
                    })
                }
            })
            this.activeProductPriceHistory.forEach(p => {
                let idx = dates.findIndex(y => p.created_at.toISODate() === y.Date);
                dates[idx][p.site.name] = p.price;
            })
          return dates;
        },
        async getProductPrices(gameSlug: string, categorySlug: string, productSlug: string, countryCode: string) {
            const response = await ProductApiClient.getProductPrices(gameSlug, categorySlug, productSlug, countryCode);
            if(response.success){
                this.activeProductPrices = response.data?.map(x => ProductPriceUtils.deApify(x));
            }
            else{
                this.activeProductPrices = [];
            }
        },
        async getCardmarketProductPrices(gameSlug: string, categorySlug: string, productSlug: string, countryCode: string) {
            const response = await ProductApiClient.getCardmarketProductPrices(gameSlug, categorySlug, productSlug, countryCode);
            if(response.success){
                this.cardmarketPrices = response.data?.map(x => ProductPriceUtils.deApify(x));
            }
            else{
                this.cardmarketPrices = [];
            }
        },
        async getProductPriceHistory(gameSlug: string, categorySlug: string, productSlug: string, countryCode: string) {
            const response = await ProductApiClient.getProductPriceHistory(gameSlug, categorySlug, productSlug, countryCode);
            if(response.success){
                this.sites = [];
                this.activeProductPriceHistory = response.data?.map((x) => {
                    if(this.sites.findIndex(y => y.slug === x.site.slug) < 0){
                        let site = x.site;
                        site.color = `#${Math.floor(Math.random()*16777215).toString(16)}`;
                        this.sites.push(site);
                    }
                    return ProductPriceUtils.deApify(x);
                });
            }
            else{
                this.activeProductPriceHistory = [];
            }
        },
        async getProductPriceStats(gameSlug: string, categorySlug: string, productSlug: string, countryCode: string) {
            this.activeProductPriceStats = undefined;
            const response = await ProductApiClient.getAvgPrice(gameSlug, categorySlug, productSlug, countryCode);
            if(response.success){
                this.activeProductPriceStats = response.data!;
            }

            return this.activeProductPriceStats;
        },
        async getEbayPrices(countryCode: string) {
            this.ebayPrices = undefined;
            const response = await ProductApiClient.getEbayPrices(
                this.activeProduct.category.game.slug,
                this.activeProduct.category.slug,
                this.activeProduct.slug, countryCode);
            if(response.success){
                this.ebayPrices = response.data!;
            }

            return this.ebayPrices;
        },
        async clearProducts() {
            this.products = undefined;
            this.activeProductPriceHistory = [];
            this.ebayPrices = undefined;
        },
        async clearActiveProduct() {
            this.activeProduct = undefined;
            this.activeProductPriceHistory = undefined;
            this.ebayPrices = undefined;
        },
        async clearBackInStockProducts() {
            this.backInStock = undefined;
        },
        async clearLatestProducts() {
            this.latest = undefined;
        },
        async clearSearchResults() {
          this.searchResults = [];
        },
        async clearDeals() {
          this.deals = [];
        }
    }
});

export default useProductStore;